<template>
	<div class="mechanism">
		<Topq></Topq>
		<div class="mechanism-main">
			<div class="mechanism-content">
				<div class="main-title">
					<ul>
						<li @click="register">个人账号</li>
						<li class="te">机构账号</li>
					</ul>
				</div>
				<div class="mechanism-ziliao">
					填写机构资料
				</div>
				<div class="mechanism-name">
					<span>机构名称：</span>
					<div class="channel">
						<el-input placeholder="请输入机构名称" v-model="companyName"></el-input>
					</div>
				</div>
				<div class="mechanism-name">
					<span>联系方式：</span>
					<div class="channel">
						<el-input placeholder="请输入联系方式" v-model="companyPhone"></el-input>
					</div>
				</div>
				<div class="mechanism-name">
					<span>机构类别：</span>
					<div class="channel">
						<el-select v-model="leiName" placeholder="请选择" style="width: 4.6rem;">
							<el-option v-for="(item,index) in leioptions" :key="index" :label="item.industryName"
								:value="item.industryId">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="mechanism-name">
					<span>机构规模：</span>
					<div class="channel">
						<el-select v-model="scale" placeholder="请选择" style="width: 4.6rem;">
							<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="mechanism-name">
					<span>成立时间：</span>
					<div class="channel">
						<el-date-picker v-model="estTime" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日"
							value-format="yyyy-MM-dd">
						</el-date-picker>
					</div>
				</div>
				<div class="mechanism-name">
					<span>注册资金：</span>
					<div class="channel">
						<el-input placeholder="单位:万元" v-model="capital"></el-input>
					</div>
				</div>
				<div class="mechanism-name">
					<span>所属区域：</span>
					<div class="channel">
						<el-input placeholder="" v-model="location"></el-input>
					</div>
				</div>
				<div class="mechanism-name">
					<span>联系地址：</span>
					<div class="channel">
						<el-input placeholder="" v-model="address"></el-input>
					</div>
				</div>
				<div class="mechanism-fanwei-mian">
					<span>经营范围：</span>
					<div class="fanwei-mian">
						<el-input type="textarea" :rows="8" placeholder="请输入内容" v-model="scope">
						</el-input>
					</div>
				</div>
				<div class="mechanism-fanwei-mian">
					<span>机构简介：</span>
					<div class="fanwei-mian">
						<el-input type="textarea" :rows="8" placeholder="请输入内容" v-model="introduce">
						</el-input>
					</div>
				</div>
				<div class="mechanism-zhuce" @click="submit">
					注册
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import md5 from 'js-md5'
	import $http from '../common/api/axios.js'
	import {
		registerCompanyUser,industryInfo
	} from '../common/api/api.js'
	import Topq from './common/Topq.vue'
	export default {
		data() {
			return {
				companyPhone:'',
				mechanismname: '',
				mechanismaccount: '',
				mechanismpassword: '',				
				address: '',
				capital: '',
				companyName: '',
				estTime: '',
				introduce: '',
				location: '',
				scale: '',
				scope: '',
				leiName:'',
				leioptions:[],
				options: [{
					value: '0',
					label: '0~10人'
				}, {
					value: '1',
					label: '10~100人'
				}, {
					value: '2',
					label: '100~300人'
				}, {
					value: '3',
					label: '300人以上'
				}],

			}
		},
		components: {
			Topq
		},
		created() {
			this.mechanismname=this.$store.state.mechanismname
			this.mechanismaccount=this.$store.state.mechanismaccount
			this.mechanismpassword=this.$store.state.mechanismpassword	
			let that=this
			this.$store.dispatch('initDic').then(() => {
				$http.request({
					url: industryInfo,
				}).then((res) => {
					that.leioptions = res.data.industryInfos
				}).catch(() => {
							
				});
				
			})
		},
		methods: {
			submit() {
					if(this.mechanismpassword==''){
						this.$message({
							message: '请重新填写账户密码',
							type: 'warning',
							duration:'2000'
						});
					}else{
						let that = this
							$http.request({
								url: registerCompanyUser,
								data: {
									"contact":that.companyPhone,
									"address": that.address,
									"capital": that.capital,
									"companyName": that.companyName,
									"estTime": that.estTime,
									"industry":that.leiName,
									"introduce": that.introduce,
									"location": that.location,
									"nickName": that.mechanismname,
									"password": md5(that.mechanismpassword),
									"phone": that.mechanismaccount,
									"productType": 4,
									"scale": that.scale,
									"scope": that.scope
								}
							}).then((res) => {
								console.log(res)
								if (res.code == 200) {
						
									this.$message({
										message: '注册成功!',
										type: 'success'
									});
						
									that.$router.push('./')
						
								}
							}).catch(() => {
						
							})
					}
			},
			register() {
				this.$router.push('./pj-register')
			},
			
		}
	}
</script>

<style scoped>
	.main-title{
		margin-bottom: .5rem;
	}
	.main-title ul {
		display: flex;
		padding: 0 1.13rem;
		width: 100%;
		height: .74rem;
	}
	
	.main-title ul li {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 3.5rem;
		height: 100%;
		font-size: .20rem;
		font-weight: 400;
		color: #666666;
	
	}
	
	.te {
		border-bottom: 3px solid #E41D1D;
		color: #E41D1D !important;
	}
	.el-input__icon {
		line-height: 0;
	}

	.reg-ti {
		font-size: .12rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #E1423F;
		line-height: .52rem;
		margin-left: .2rem;
	}

	.mechanism-zhuce {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: .58rem;
		margin-left: 2.95rem;
		width: 4.61rem;
		height: .6rem;
		background: #E41D1D;
		border-radius: 4px;
		color: #FFFFFF;
		cursor: pointer;
	}

	/deep/.el-textarea__inner {
		width: 4.6rem;
		height:1.44rem!important ;
		font-size: .14rem;
		font-family: PingFang SC;
		color: #666666;
		background-color:#F5F5F5!important
	}

	.mechanism-fanwei-mian {
		display: flex;
		margin-left: 2.1rem;
	}

	.mechanism-fanwei-mian>span:before {
		content: '* ';
		color: red;
	}

	.fanwei-mian {
		margin-bottom: .24rem;
		width: 4.6rem;
		
	}

	.mechanism-fanwei-mian span {
		display: inline-block;
		width: .85rem;
		line-height: .52rem;
		font-size: .14rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #666666;
	}

	.mechanism-ziliao {
		margin-top: .64rem;
		margin-bottom: .4rem;
		margin-left: 2.4rem;
		font-size: .2rem;
		font-family: PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.mechanism {
		display: flex;
		flex-direction: column;
	}

	.mechanism-main {
		padding-top: 1.48rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 19.2rem;
		background: url(../assets/image/bg5.png) no-repeat;
		background-size: 100%;
	}
	.mechanismtop-left {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 6.4rem;
		height: 1rem;
		background: #F5F5F5;
		box-shadow: 0px 2px 8px 0px rgba(109, 33, 237, 0.45);
		border-radius: 2px;
		font-size: .18rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
	}

	.mechanismtop-right {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 6.4rem;
		height: 1rem;
		background: #762FEF;
		box-shadow: 0px 2px 8px 0px #F5F5F5;
		border-radius: 2px;
		font-size: .18rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;

	}

	.mechanism-content {
		margin-bottom: 1.48rem;
		width: 9.74rem;
		background: rgb(255,255,255,0.85);
		box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.43);
		border-radius: 10px;
	}

	.mechanism-name {
		display: flex;
		margin-bottom: .2rem;
		margin-left: 2.1rem;
	}

	.mechanism-name span {
		margin-right: .04rem;
		line-height: .52rem;
		font-size: .14rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #666666;
	}

	.mechanism-name>span:before {
		content: '* ';
		color: red;
	}

	.mechanism-account {
		display: flex;
		margin-left: 2.5rem;

	}

	.mechanism-account span {
		line-height: .52rem;
		font-size: .14rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #666666;
		margin-left: -.1rem;
	}

	.mechanism-account>span:before {
		content: '* ';
		color: red;
	}

	.mechanism-password {
		display: flex;
		margin-top: .2rem;
		margin-left: 2.4rem;
	}

	.mechanism-password span {
		line-height: .52rem;
		font-size: .14rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #666666;
	}

	.mechanism-span:before {
		content: '* ';
		color: red;
	}
	.el-input{
	width: 4.6rem !important;
	height: .52rem !important;
	font-size: .14rem !important;
	font-family: PingFang SC !important;
	font-weight: 4100 !important;
	color: #333333 !important;
	}
	/deep/.el-input__inner {
		width: 4.6rem !important;
		height: .52rem !important;
		font-size: .14rem !important;
		font-family: PingFang SC !important;
		font-weight: 400 !important;
		color: #333333 !important;
		background-color:#F5F5F5!important
	}

	input.el-input__inner::-webkit-input-placeholder {
		color: #999999 !important;
		font-weight: 400 !important;
	}

	input.el-input__inner::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #999999 !important;
	}

	input.el-input__inner:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #999999 !important;
	}

	input.el-input__inner:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #999999 !important;
	}

	.el-input {
		font-size: .14rem !important;
	}

	.el-input__suffix {
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>
